import selectBtn from "@/components/buttons/selectBtn/selectBtn.vue";
import displayPictures from "../displayPictures/displayPictures.vue";
import successiveSynthesis from "../successiveSynthesis/successiveSynthesis.vue";
import sleep from "@/helpers/sleep";
export default {
  name: "successiveQuestions",
  components: {
    selectBtn,
    displayPictures,
    successiveSynthesis
  },
  props: {
    availableAnswers: {
      type: Array,
      default: []
    },
    pictures: {
      type: Array
    },
    goodAnswer: {
      default: null
    },
    showSynthesis: {
      type: Boolean,
      default: false
    },
    onLastSection: {
      type: Boolean,
      default: false
    },
    synthesisText: {
      type: String,
      default: null
    },
    step: {
      type: Number,
      default: null
    },
    stepSynthesisDiagram: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showCorrection: false,
      resetBtn: false,
      answered: null
    };
  },
  computed: {
    type() {
      if (Array.isArray(this.goodAnswer)) {
        return "checkbox";
      }
      return "radio";
    },
    // Return the number of good answers when is type is checkbox
    numberOfAnswers() {
      if (this.type === "checkbox") {
        return this.goodAnswer.length;
      }
      return 1;
    }
  },
  methods: {
    async answerAction(value) {
      // Increment value for multi answers case
      this.answered = this.answered + 1;
      if (this.type === "checkbox") {
        await this.valideCheckboxAnswer();
      } else {
        if (!value) {
          await sleep(500);
          this.showCorrection = true;
        }
        await this.goToSynthesis();
      }
    },
    // Show good answers when maximum answers are completed
    async valideCheckboxAnswer() {
      if (this.answered === this.numberOfAnswers) {
        await sleep(500);
        this.showCorrection = true;
        await this.goToSynthesis();
      }
    },
    // Automatic transition to synthesis
    async goToSynthesis() {
      await sleep(1500);
      this.resetBtn = true;
      this.showCorrection = false;
      this.answered = null;
      this.$emit("showSynthesis");
      this.$nextTick(() => {
        this.resetBtn = false;
      });
    },
    nextSection() {
      this.$emit("nextSection");
    }
  }
};

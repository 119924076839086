import { mapGetters } from "vuex";
import plainBtn from "@/components/buttons/plainBtn/plainBtn.vue";
import synthesisText from "@/components/synthesisText/synthesisText.vue";
import displayIcon from "@/components/displayIcon/displayIcon.vue";

export default {
  name: "successiveSynthesis",
  components: {
    plainBtn,
    synthesisText,
    displayIcon
  },
  props: {
    onLastSection: {
      type: Boolean,
      default: false
    },
    synthesisText: {
      type: String,
      default: null
    },
    synthesisDiagram: {
      type: String,
      default: null
    }
  },
  computed: {
    buttonTitle() {
      return this.onLastSection ? "Retourner à l'accueil" : "Suivant";
    },
    ...mapGetters(["actualSuccessiveStep"])
  },
  methods: {
    resetChapter() {
      // this.$store.dispatch("resetFindAssociation");
      // this.$router.push({
      //   name: "Home"
      // });
    },
    nextSection() {
      this.$emit("nextSection");
    }
  }
};

export default {
  name: "selectBtn",
  props: {
    index: {
      type: Number
    },
    title: {
      type: String
    },
    goodAnswer: {
      type: [String, Array]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showCorrection: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "radio"
    }
  },
  data() {
    return {
      isGood: null,
      isError: null
    };
  },
  computed: {
    isCorrection() {
      if (this.showCorrection && !this.isGood && !this.isError) {
        if (this.type === "checkbox") {
          return this.goodAnswer.includes(this.title)
        } else {
          return this.title === this.goodAnswer;
        }
      }
      return null;
    }
  },
  methods: {
    sendValue() {
      this.validateAnswer();
      this.$emit("isClicked", this.isGood);
    },
    validateAnswer() {
      if (this.type === "checkbox") {
        this.isGood = this.goodAnswer.includes(this.title);
        this.isError = !this.isGood;
      } else {
        this.isGood = this.title === this.goodAnswer;
        this.isError = this.title !== this.goodAnswer;
      }
    }
  }
};

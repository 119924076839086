export default {
  name: "displayPictures",
  props: {
    pictures: {
      type: Array
    }
  },
  computed: {
    formatedPictures() {
      return this.pictures.map(picture => {
        return {
          src: this.generatedUrl(picture),
          description: "",
          alt: picture,
          thumbnailWidth: '300px'
        };
      });
    }
  },
  methods: {
    generatedUrl(picture) {
      return require(`@/assets/pictures-chapter-two/${picture}.jpg`);
    }
  }
};
